<template>
  <div>
    <!-- codepush -->
  </div>
</template>

<script setup>
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { codePush, InstallMode } from '@nerdfrenzs/capacitor-codepush';

const SyncStatus = {
  UP_TO_DATE: 0,
  UPDATE_INSTALLED: 1,
  UPDATE_IGNORED: 2,
  UNKNOWN_ERROR: 3,
  SYNC_IN_PROGRESS: 4,
  CHECKING_FOR_UPDATE: 5,
  AWAITING_USER_ACTION: 6,
  DOWNLOADING_PACKAGE: 7,
  INSTALLING_UPDATE: 8,
};

// Methods
const sync = async() => {
  const loading = ElLoading.service({
    lock: true,
  });

  try {
    const currentDeployment = useUIStore().codePush.currentDeployment;
    const currentDeploymentKey = useUIStore().codePush.deploymentKeys[currentDeployment];

    if (!currentDeployment || !currentDeploymentKey) {
      console.warn('[CodePush] No deployment key found', { currentDeployment, currentDeploymentKey });
      loading?.close();
      return;
    }

    // Notify that the app is ready, which will clear the failed status
    await codePush.notifyApplicationReady();
    console.log('[CodePush] Notified application ready');

    console.log('[CodePush] Check for update', currentDeployment);
    const downloadProgress = (progress) => {
      if (progress) {
        console.log(`[CodePush] Downloading ${progress?.receivedBytes} of ${progress.totalBytes}`);
      }
    };

    codePush.checkForUpdate((update) => {
      console.log('[CodePush] Update', update);

      if (update) {
        codePush.sync({
          deploymentKey: currentDeploymentKey,
          installMode: InstallMode.IMMEDIATE,
          ignoreFailedUpdates: true,
          rollbackRetryOptions: {
            delayInHours: 1,
            maxRetryAttempts: 3,
          },
          onSyncStatusChanged: (status) => {
            console.log('[CodePush] Sync status', status);

            if (![SyncStatus.SYNC_IN_PROGRESS, SyncStatus.CHECKING_FOR_UPDATE, SyncStatus.DOWNLOADING_PACKAGE, SyncStatus.INSTALLING_UPDATE].includes(status)) {
              loading?.close();
            }
          },
          onSyncError: (error) => {
            console.log('[CodePush] Sync error', error);
            loading?.close();
          },
        }, downloadProgress);
      } else {
        loading?.close();
      }
    }, (error) => {
      loading?.close();
      console.log('error', error);
    }, currentDeploymentKey);
  } catch (error) {
    loading?.close();
    console.log('error', error);
  }
};

onMounted(async() => {
  if (Capacitor.getPlatform() === 'ios') {
    try {
      const currentPackage = await codePush.getCurrentPackage();
      console.log('[CodePush] Current package', currentPackage);

      const appInfo = await App.getInfo();
      console.log('[Capacitor] App info', appInfo);

      let releaseVersion = appInfo?.version;

      if (currentPackage) {
        releaseVersion = `${currentPackage.appVersion || ''}+${currentPackage.label}`;
      }

      console.log('[Capacitor] Release version', releaseVersion);

      if (useNuxtApp().$sentry) {
        useNuxtApp().$sentry?.getCurrentScope().setExtra('release', releaseVersion);
      }
      useUIStore().codePush.release = releaseVersion;

      const currentDeployment = currentPackage?.deploymentKey ? Object.keys(useUIStore().codePush.deploymentKeys).find((key) => useUIStore().codePush.deploymentKeys[key] === currentPackage.deploymentKey) : 'production';
      useUIStore().codePush.currentDeployment = currentDeployment;
    } catch (error) {
      console.log('error', error);
    }

    // deep link
    App.addListener('appUrlOpen', (event) => {
      console.log('[Capacitor] App url open', event);
      try {
        const url = new URL(event.url);
        const fullPath = url.pathname + url.search;
        const currentFullPath = window.location.pathname + window.location.search;

        console.log('[Capacitor] Deep linking to path:', fullPath);

        // Navigate to the path
        if (fullPath && fullPath !== '/' && fullPath !== currentFullPath) {
          window.location.href = fullPath;
        }
      } catch (error) {
        console.error('[Capacitor] Error handling deep link:', error);
      }
    });
  }
});

// Watchers
watch(() => useUIStore().codePush.currentDeployment, () => {
  sync();
});
</script>
